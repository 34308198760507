import React from "react"

const Row = ({children, size, image, classes, gradientTop, gradientBottom, id, video}) => (
  <div className={ "wrapper bg-wrapper " + classes} style={image != null ? {backgroundImage: `url(${image})`}: null} id={id}>

    {video != null ?
      <div className="background-video-container">
        <video className="background-video" muted src={video.video} poster={video.poster} autoPlay loop/>
      </div>
    : null }

    {gradientTop && <div className="gradient-top" style={{backgroundImage: `url(${gradientTop})`}} />}
    <div className={"container z-200 " + size}>
       {children}
    </div>
    {gradientBottom && <div className="gradient-bottom" style={{backgroundImage: `url(${gradientBottom})`}} />}
  </div>
)

export default Row
