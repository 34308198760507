import { Link } from "gatsby"
import React, { Component } from "react"
import logo from "../images/logo-header.svg"
import { slide as Menu } from "react-burger-menu"
import text from "../data/nl"

class Header extends Component {

  constructor(props) {
    super(props)
    this.props = props;
  }

  render() {
    return (
      <>
        <header className={"wrapper z-300 transition-background fixed " + (this.props.scroll ? "scroll-header" : "default-header")}>
          <div className="absolute top-0 left-0 right-0 h-120 menu-gradient fixed" style={this.props.gradientOpacity}/>
          <div className="container small full-height">
            <div className="column col-12 full-height">
              <div className="relative full-height">
                <div className="mt-80 absolute bottom-0 left-0">
                  <Link to="/">
                    <img className="header-logo" src={logo} alt="DDCgroup Logo"/>
                  </Link>
                </div>
                <nav className="nav-main mw-830 full-width absolute bottom-0 right-0 pb-20 hide-1176">
                  <ul className="list-style-none full-width  uppercase bold t-18 align-right">
                    <li className="float-right ml-20-imp"><Link to="/contact" activeClassName="active">{text.menu.contact}</Link></li>
                    <li className="float-right ml-20-imp"><Link to="/vacatures" activeClassName="active">{text.menu.jobs}</Link></li>
                    <li className="float-right ml-20-imp"><Link to="/nieuws" activeClassName="active">{text.menu.news}</Link></li>
                    <li className="float-right ml-20-imp"><Link to="/over-ddcgroup" activeClassName="active">{text.menu.aboutUs}</Link></li>
                    <li className="float-right ml-20-imp"><Link to="/klanten" activeClassName="active">{text.menu.customers}</Link></li>
                    <li className="float-right ml-20-imp"><Link to="/oplossingen" activeClassName="active">{text.menu.solutions}</Link></li>
                    <li className="float-right ml-20-imp"><Link to="/">{text.menu.home}</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <Menu className="bold uppercase" right>
          <Link className="mb-20" to="/">{text.menu.home}</Link>
          <Link className="mb-20" to="/oplossingen" activeClassName="active">{text.menu.solutions}</Link>
          <Link className="mb-20" to="/klanten" activeClassName="active">{text.menu.customers}</Link>
          <Link className="mb-20" to="/over-ddcgroup" activeClassName="active">{text.menu.aboutUs}</Link>
          <Link className="mb-20" to="/nieuws" activeClassName="active">{text.menu.news}</Link>
          <Link className="mb-20" to="/vacatures" activeClassName="active">{text.menu.jobs}</Link>
          <Link className="mb-20" to="/contact" activeClassName="active">{text.menu.contact}</Link>
        </Menu>
      </>
    )
  }
}

export default Header
