import _ from "lodash"
import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, } from "react-google-maps"
import conf from "../config/maps.conf"

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false
};

const MyMapComponent = compose(
  withProps({
    googleMapURL:`https://maps.googleapis.com/maps/api/js?key=${conf.key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `193px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }), withScriptjs, withGoogleMap)(props => (
    <GoogleMap defaultZoom={conf.zoom} defaultCenter={{ lat: conf.lat, lng: conf.lng }} defaultOptions={defaultMapOptions}>
      <Marker position={{ lat: conf.marker.lat, lng: conf.marker.lng }} />
    </GoogleMap>
  )
)

const enhance = _.identity
const ReactGoogleMaps = () => [<MyMapComponent key="map" />]

export default enhance(ReactGoogleMaps)
