// home video bg
import homeBg from "../images/home/bg.mp4"
import homeBgPoster from "../images/home/bg-poster.png"

// abilities
import make from "../images/abilities/chart-line.svg"
import system from "../images/abilities/server.svg"
import info from "../images/abilities/info-circle.svg"
import sliders from "../images/abilities/sliders-h.svg"
import is from "../images/abilities/is.svg"
// partners
import bettyblocks from "../images/partners/bettyblocks.svg"
import jedox from "../images/partners/jedox.png"
import mendix from "../images/partners/mendix.svg"
import qlik from "../images/partners/qlik.svg"
import relatics from "../images/partners/relatics.png"
import timextender from "../images/partners/timextender.png"
import vizlib from "../images/partners/vizlib.png"
import nodegraph from "../images/partners/nodegraph.png"

import smallbettyblocks from "../images/partners/small/bettyblocks.svg"
import smalljedox from "../images/partners/jedox.png"
import smallmendix from "../images/partners/mendix.svg"
import smallNodegraph from "../images/partners/nodegraph.png"
import smallqlik from "../images/partners/small/qlik.svg"
import smallrelatics from  "../images/partners/relatics.png"

import crownTuin from "../images/about-ddc/crown-tuin.jpg"

import solutionBettyblocks from "../images/solutions/deepblue/bettyblocks.svg"
import solutionMendix from "../images/partners/mendix.svg"
import solutionJedox from "../images/partners/jedox.png"
import solutionQlik from "../images/solutions/business-intelligence/qlik.svg"
import solutionIndigo from "../images/solutions/infoscience/indigo.png"
import solutionMaatwerk from "../images/solutions/deepblue/code.png"
import solutionVizlib from "../images/solutions/business-intelligence/vizlib.png"
import solutionTimextender from "../images/solutions/business-intelligence/timextender.png"
import solutionNodegraph from "../images/solutions/business-intelligence/nodegraph.png"
import productUrenBoeken from "../images/solutions/products/online-urenregistratie-software.png"
import productQDE from "../images/solutions/products/qlik-data-extractor.png"

import jij from "../images/about-ddc/jij.jpg"

export default {
  menu: {
    home: "Home",
    solutions: "Oplossingen",
    customers: "Klanten",
    aboutUs: "Over DDCgroup",
    news: "Nieuws",
    jobs: "Vacatures",
    contact: "Contact",
  },
  footer: {
    menu: "Menu.",
    contact: {
      title: "Contact.",
      emailAddress: "info@ddcgroup.com",
      phoneNumber: "+31(0)161 747 828",
      location: "Crown Business Center",
      address: "Ericssonstraat 2",
      postalCode: "5121 ML",
      city: "Rijen",
    },
  },
  home: {
    meta: {
      title: "Wij kennen de kracht van informatie",
      description: "Met de juiste informatie functioneert uw bedrijf efficienter, en bent u de concurrentie een stap voor."
    },
    jumbotron: {
      title: "Dedicated",
      subtitle: "To your information needs.",
      callToAction: "Neem contact op",
      callToActionLink: "/contact",
      video: {
        video: homeBg,
        poster: homeBgPoster
      }
    },
    slogan: "Wij kennen de kracht van informatie",
    explanation: {
      title:
        "Met de juiste informatie functioneert uw bedrijf efficienter, en bent u de concurrentie een stap voor.",
      text:
        "Informatie is een onmisbaar ingrediënt voor een gezonde bedrijfsvoering.  Bij DDC onderkennen wij de kracht van informatie. We helpen u om te bepalen welke informatie voor uw organisatie belangrijk is en bieden vervolgens oplossingen om deze informatie overzichtelijk beschikbaar te stellen. Een team van meer dan 30 professionals staat voor u klaar, om uw informatiesystemen te optimaliseren. Wij maken uw organisatie slimmer, effectiever en bieden nieuwe mogelijkheden.",
    },
    abilities: [
      {
        image: make,
        title: "BI & Performance Management",
        ref: "/business-intelligence-and-performance-management",
        text:
          "Verkoop- en implementatiepartner voor BI (QlikView, Qlik Sense, TimeXtender, PowerBI en add-ons) en Corporate Performance Management (Jedox en Board)",
      },
      {
        image: system,
        title: "Software development",
        ref: "/software-development",
        text:
          "Ontwerp en ontwikkeling van maatwerk software. Rapid Application Development (Mendix, Betty Blocks), Java, .NET, detachering.",
      },
      {
        image: is,
        title: "Info sciences",
        ref: "/info-sciences",
        text:
          "Informatie- en Data Governance, Migraties, Master Data Management, Data kwaliteit, Business- en informatie-analyse."
      },
      {
        image: sliders,
        title: "Alignment",
        ref: "/alignment",
        text:
        "Performance verbetering door stroomlijnen processen, organisatie en techniek. Strategisch advies, Projectmanagement, Lifecycle management, Kwaliteitsborging."
      },
    ],
    partners: {
      title: "Onze partners.",
      partners: [
        {
          image: bettyblocks,
          alt: "Betty Blocks",
          ref: "/betty-blocks",
          imageHeight: "73%"
        },
        {
          image: jedox,
          alt: "Jedox",
          ref: "/jedox"
        },
        {
          image: mendix,
          alt: "Mendix",
          ref: "/mendix"
        },
        {
          image: nodegraph,
          alt: "Nodegraph",
          ref: "/nodegraph",
          imageHeight: "90%",
        },
        {
          image: qlik,
          alt: "Qlik",
          ref: "/qlik",
          imageHeight: "70%"
        },
        {
          image: relatics,
          alt: "Relatics",
          ref: "/relatics"
        },
        {
          image: timextender,
          alt: "TimeXtender",
          ref: "/timextender",
          imageHeight: "40%",
        },
        {
          image: vizlib,
          alt: "Vizlib",
          imageHeight: "70%",
          ref: "/vizlib"
        },
      ],
    },
    businessUnits: [
      {
        title: "Software Development",
        ref: "/software-development",
      },
      {
        title: "Business Intelligence",
        ref: "/business-intelligence-and-performance-management",
      },
      {
        title: "Information Science",
        ref: "/info-sciences",
      },
      {
        title: "Alignment",
        ref: "/alignment",
      },
    ],
    news: {
      title: "Laatste nieuws."
    }

  },

  clients: {
    title: "Klanten.",
    meta: {
      title: "Klanten",
      description: "DDC werkt voor een indrukwekkende lijst van bedrijven, van groot tot klein. Lees hier meer over enkele projecten."
    },
  },
  aboutUs: {
    meta: {
      title: "Over ons",
      description: "DDC, bij ons staat informatie centraal. Maak kennis met onze consultants met kennis van Qlik, Truechart, Jedox, Mendix, Betty Blocks en maatwerksoftware."
    },
    intro: {
      title: "Wij zijn DDCgroup.",
      descriptionFirstPart: "<p>Informatie is een onmisbaar ingrediënt voor een gezonde bedrijfsvoering. Bij DDC kennen wij de kracht van informatie. We zijn in staat om te bepalen welke informatie voor uw organisatie belangrijk is, en vervolgens oplossingen te bieden die deze informatie overzichtelijk beschikbaar stelt.</p><p>Een team van meer dan 30 professionals staat voor u klaar, om uw informatiesystemen te optimaliseren. Wij maken uw organisatie slimmer, effectiever en bieden nieuwe mogelijkheden. DDCgroup bestaat uit vier business units: BI &amp; PM consultancy, Info Sciences, Software Development en Alignment. Consultancy rondom data om organisaties te helpen slimmere beslissingen te nemen is wat de vier business units verbindt. Ieder heeft zijn eigen expertise, maar vaak is een combinatie nodig om tot het beste resultaat te komen. Het klantbelang staat altijd voorop. Door het bundelen van onze krachten is er altijd een passende oplossing voor uw organisatie.</p>",
      descriptionSecondPartTitle: "Waarom DDC?",
      descriptionSecondPart: "<p>De kernwaarden van DDC zijn betrokkenheid, ervaring en innovatie. Door de ruime ervaring van onze consultants zijn we in staat om snel een écht goed advies te geven. En vervolgens zetten we ons volledig in om samen met u te komen tot het gewenste resultaat. De meeste expertise die nodig is in een project is binnen DDC aanwezig, maar indien nodig schakelen we externe hulp uit ons uitgebreide netwerk in. Onze ervaring is niet beperkt tot één specifieke sector: we hebben ervaring in onder andere de financiële, technische, logistieke en productie sector. We zijn constant op zoek naar innovatieve oplossingen die onze klanten helpen bij het inzichtelijk maken van informatie. We investeren regelmatig in eigen tools en technieken, die we vervolgens beschikbaar maken in de markt. We nodigen u uit voor een vrijblijvend kennismakingsgesprek, waarin we u graag overtuigen van onze kennis en de commitment om samen tot mooie resultaten voor uw bedrijf te komen.</p>",
      image: crownTuin
    },
    partners: {
      title: "Onze partners.",
      partners: [
        {
          image: smallbettyblocks,
          alt: "Betty Blocks",
          ref: "/betty-blocks"
        },
        {
          image: smalljedox,
          alt: "Jedox",
          ref: "/jedox"
        },
        {
          image: smallmendix,
          alt: "Mendix",
          ref: "/mendix"
        },
        {
          image: smallNodegraph,
          alt: "Nodegraph",
          ref: "/nodegraph",
          imageHeight: "90%"
        },
        {
          image: smallqlik,
          alt: "Qlik",
          ref: "/qlik"
        },
        {
          image: smallrelatics,
          alt: "Relatics",
          ref: "/relatics"
        },
      ],
    },
    team: {
      title: "Ons team.",
      description: "Wij werken met een team van 30 ervaren professionals in Nederland, en 30 ontwikkelaars en testers in Oost-Europa. Maak hieronder kennis met enkele van onze medewerkers.",
      you: {
        name: "Jij?",
        image: jij,
        ref: "/werken-bij-ddc",
        alt: "Werken bij DDC?"
      }
    }
  },
  news: {
    meta: {
      title: "Nieuws ",
      description: "Het laatste nieuws over software ontwikkeling, data analyse BI en de andere activiteiten van DDC."
    },
    title: "Nieuws.",
    readMore: "Lees verder...",
    relatedArticles: "Gerelateerd nieuws",
    back: "Terug"
  },
  pagination: {
    next: "Volgende",
    prev: "Vorige"
  },
  contact: {
    meta: {
      title: "Contact ",
      description: "Neem contact op met DDC als u behoefte heeft aan informatie of informatiesystemen"
    },
    route: "<b>Wij zijn gevestigd in het Crown business park<br/>aan de Ericssonstraat 2 (5121 ML) te Rijen (tussen Tilburg en Breda)<br/></b>Tegenover de luchtmachtbasis gaat u de Julianastraat in, en dan direct rechts naar de Ericssonstraat. Daar neemt u de tweede oprit aan de rechterkant.<br/>Op het terrein de weg volgen (bocht naar links) totdat u op een parkeerplaats uit komt (P1/P2). Het Crown business center ziet u dan links.",
    title: "Contact.",
    form: {
      name: "Uw naam",
      company: "Bedrijf",
      emailAddress: "Uw emailadres",
      subject: "Onderwerp",
      message: "Uw bericht",
      phone: "Telefoonnummer",
      submit: "Verstuur",
      errors: {
        notEmpty: "Mag niet leeg zijn.",
        invalidEmail: "Voer een correct emailadres in."
      },
      thanks: "Dank voor uw interesse in DDC"
    }
  },
  customers: {
    title: "Klanten.",
    meta: {
      title: "Klanten",
      description: "Bij deze bedrijven hielp DDC om data om te zetten in informatie"
    },
  },
  solutions: {
    meta: {
      title: "Oplossingen",
      description: "Met de juiste informatie functioneert uw bedrijf efficienter, en bent u de concurrentie een stap voor."
    },

    title: "Oplossingen.",
    description: "Door slim gebruik te maken van de juiste tools en producten, zijn we in staat om goede oplossingen te bieden aan onze klanten. In het overzicht hieronder staan de producten waarvan wij implementatie partner zijn.<br/>Onze inzichten en ervaringen leiden soms ook tot ontwikkeling van eigen producten, die we in de markt aanbieden. Deze beschrijven we onderaan deze pagina.",
    businessUnits: [
      {
        title: "BI & Performance Management",
        ref: "/business-intelligence-and-performance-management",
        image: make,
        description: "Verkoop- en implementatiepartner voor BI (QlikView, Qlik Sense, TimeXtender, PowerBI en add-ons) en Corporate Performance Management (Jedox en Board)",
        solutions: [
          {
            image: solutionQlik,
            ref: "/qlik",
            alt: "QlikView",
            description: "Analyses en rapportages maken, waarbij u alle data in elke richting kunt onderzoeken zonder data te missen of analyse-paden over te slaan (dankzij de beroemde Associative Engine van Qlik)."
          },
          {
            image: solutionNodegraph,
            ref: "/nodegraph",
            alt: "Node Graph",
            description: "Nodegraph biedt een data intelligence platform waarmee u de data stromen van uw BI platform (Qlik, Tableau of Power BI) inzichtelijk maakt. Hiermee krijgt uw organisatie meer controle en vertrouwen in uw data."
          },
          {
            image: solutionJedox,
            ref: "/jedox",
            alt: "Jedox",
            description: "Planningen, analyses en rapportages gemakkelijk, snel en kosteneffectief maken met het innovatieve, volledig geïntegreerde Jedox platform. Driver-based planning in een op Excel lijkende omgeving maar met de voordelen van Jedox."
          },
        ]
      },
      {
        title: "Software development",
        image: system,
        description: "Ontwerp en ontwikkeling van maatwerk software. Rapid Application Development (Mendix, Betty Blocks), Java, .NET, detachering.",
		    ref: "/software-development",
        solutions: [
          {
            image: solutionMendix,
            ref: "/mendix",
            alt: "Mendix",
            description: "Mendix is een software development platform waarmee zonder programmeren (dus snel en flexibel) een compleet informatiesysteem kan worden ingericht."
          },
          {
            image: solutionBettyblocks,
            ref: "/betty-blocks",
            alt: "Bettyblocks",
            description: "Betty Blocks is een software development platform waarmee zonder programmeren (dus snel en flexibel) een compleet informatiesysteem kan worden ingericht."
          },
          {
            image: solutionMaatwerk,
            ref: "/software-op-maat-met-java-of-net",
            alt: "Software op maat",
            description: "Onze ontwerpers, ontwikkelaars en testers ontwikkelen software op maat. Dit doen we met behulp van talen en tools zoals Java, C#, NodeJs, Angular, React, Spring en .NET"
          }
        ]
	    },
      {
        title: "Info sciences",
        image: is,
        description: "Informatie- en Data Governance, Migraties, Master Data Management, Data kwaliteit, Business- en informatie-analyse.",
        ref: "/info-sciences",
        solutions: [
          {
            image: solutionIndigo,
            ref: "/indigo",
            alt: "Indigo",
            description: "Met Indigo wordt het informatie landschap binnen uw bedrijf in kaart gebracht. Op drie niveaus wordt alles vastgelegd over de informatie, processen en verantwoordelijken (RASCI)."
          },
          {
            image: solutionVizlib,
            ref: "/vizlib",
            alt: "vizlib",
            description: "Vizlib biedt geavanceerde Qlik Sense extensies waarmee dashboards nog krachtiger worden zoals: Een modern Finance report voor P&L en balans sheetsRuim 20 pixel perfect visualizaties."
          },
          {
            image: solutionTimextender,
            ref: "/timextender",
            alt: "TimeXtender",
            description: "Met TimeXtender kunnen bedrijven een modern data-estate opbouwen door de overstap te maken naar een geïntegreerd platform voor databeheer dat tien keer snellere data-inzichten oplevert."
          }
        ]
      },
      {
        title: "Alignment",
        ref: "/alignment",
        image: sliders,
        description: "Performance verbetering door stroomlijnen processen, organisatie en techniek. Strategisch advies, Projectmanagement, Lifecycle management, Kwaliteitsborging.",
        solutions: []
      },
    ],

    products: {
      title: "Producten",
      description: "Soms hebben we zelf behoefte aan een oplossing die nog niet bestaat. Dan ontwikkelen we deze zelf. Op die manier zijn onderstaande producten ontstaan. Mocht u interesse hebben in gebruik van deze tools, laat het ons dan even weten. ",
      products: [
        {
          image: productUrenBoeken,
          ref: "/online-urenregistratie-software",
          alt: "Uren boeken",
          description: "Dé tool voor uren-registratie die het de gebruikers makkelijk maakt. Snelheid, gemak en een goed design waren de belangrijkste uitgangspunten"
        },
        {
          image: productQDE,
          ref: "/qlik-data-extractor",
          alt: "Qlik Data Extractor",
          description: "Maak Qlik data eenvoudig beschikbaar in andere toepassingen. Bijvoorbeeld in platforms als Mendix en Betty Blocks, of in een web-based dashboard."
        },
      ]
    }
  },
  timextender: {
    meta: {
      title: "TimeXtender",
      description: "Met TimeXtender kunnen bedrijven een modern data-estate opbouwen door de overstap te maken naar een geïntegreerd platform voor databeheer dat tien keer snellere data-inzichten oplevert."
    }
  },
  pageNotFound: {
    title: "Oeps!",
    subtitle: "We kunnen de pagina die u zoekt helaas niet vinden.",
    code: "Error code: 404",
    helpfulLinksTitle: "Misschien heeft u iets aan één van deze pagina's:",
    helpfulLinks: [
      { title: "Home", ref: "/" },
      { title: "Oplossingen", ref: "/oplossingen" },
      { title: "Over DDCgroup", ref: "/over-ddcgroup" },
    ]
  }
}
