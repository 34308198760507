import React, { Component } from "react"
import Header from "./header"
import "../styles/style.scss"
import Footer from "./footer"

class Layout extends Component {


  breakpoint = 1;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.props = props;
    this.state = { headerStyles: this.defaultHeaderStyles, gradientOpacity: {opacity: 0}}
  }

  render() {
    return (
      <>
        <Header scroll={this.state.scroll} gradientOpacity={this.state.gradientOpacity}/>
        <main>{this.props.children}</main>
        <Footer bigfoot={this.props.bigfoot} hideMap={this.props.hideMap}/>
      </>
    )
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    if (this._isMounted) {
      const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      if (this.props.setScrollPosition != null) this.props.setScrollPosition(scrollPos);
      this.setState(() => {
        return { gradientOpacity: { opacity: scrollPos / 400 }, scroll: (scrollPos >= this.breakpoint) }
      });
    }
  }
}

export default Layout
