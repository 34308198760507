import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo-footer.svg"
import MapContainerFooter from "./mapContainerFooter"
import text from "../data/nl"

const Footer = ({bigfoot, hideMap}) => (
  <footer className="wrapper" id="Footer">
    <div className={!bigfoot ? "container small " : "container"}>
      <div className="hbox space-between pt-120 pb-150">
        <div className="column col-3 footer-logo-container">
          <img src={logo} className="mt-95 footer-logo" alt=""/>
        </div>
        <div className="column col-3 menu-container">
          <h4 className="t-30 bold uppercase mb-35">{text.footer.menu}</h4>
          <nav>
            <ul className="list-style-none bold">
              <li className="mb-9"><Link to="/">{text.menu.home}</Link></li>
              <li className="mb-9"><Link to="/oplossingen">{text.menu.solutions}</Link></li>
              <li className="mb-9"><Link to="/klanten">{text.menu.customers}</Link></li>
              <li className="mb-9"><Link to="/over-ddcgroup">{text.menu.aboutUs}</Link></li>
              <li className="mb-9"><Link to="/nieuws">{text.menu.news}</Link></li>
              <li className="mb-9"><Link to="/vacatures">{text.menu.jobs}</Link></li>
              <li className="mb-9"><Link to="/contact">{text.menu.contact}</Link></li>
            </ul>
          </nav>
        </div>
        <div className="column col-3 address-container">
          <h4 className="t-30 bold uppercase mb-35">{text.footer.contact.title}</h4>
          <address className="bold vbox space-between h-208">
            <div><a href="mailto:info@ddcgroup.com">{text.footer.contact.emailAddress}</a></div>
            <div><a href={"tel:" + text.footer.contact.phoneNumber}>{text.footer.contact.phoneNumber}</a></div>
            <div>&nbsp;</div>
            <div>{text.footer.contact.location}</div>
            <div>{text.footer.contact.address}</div>
            <div>{text.footer.contact.postalCode}&nbsp;&nbsp;{text.footer.contact.city}</div>

          </address>
        </div>
        <div className="column col-3 pl-0 map-container">
          <div className="h-200 mt-95">
            <div className={"pb-7 " + (hideMap ? "hidden" : "")}>
              <MapContainerFooter/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <script
      dangerouslySetInnerHTML={{
        __html: `
             (function(){ window.ldfdr = window.ldfdr || {}; (function(d, s, ss, fs){ fs = d.getElementsByTagName(s)[0]; function ce(src){ var cs = d.createElement(s); cs.src = src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1); } ce(ss); })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_bElvO73qbJGaZMqj.js'); })();
        `,
      }}
    />

  </footer>
)

export default Footer
